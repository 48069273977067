import React from 'react';
import SocialShare from '../others/SocialShare';

const SpeakerInfo = ({ speakerInfo }) => {
    const { thumb, name , bio} = speakerInfo
    return (
        <>
            <section className="speaker-detail">
                <div className="auto-container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12">
                            <div className="image-box">
                                <figure className="image"><img src={`../images/resource/${thumb}`} alt="image" /></figure>
                                {/* <ul className="social-icon-two social-icon-colored text-center">
                                    <SocialShare />
                                </ul> */}
                            </div>
                        </div>
                        <div className="info-column col-lg-8 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="text-box">
                                    <h3>{name}</h3>
                                    <p>{bio}</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default SpeakerInfo;