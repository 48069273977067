import React from 'react';
import AboutV1Data from '../../jsonData/about/AboutV1Data.json'
import { HashLink as Link } from 'react-router-hash-link'
import ReactWOW from 'react-wow';

import aboutImg from "../../assets/images/resource/banner1.jpeg"

import promo1 from "../../assets/images/resource/promo1.jpg"
import promo2 from "../../assets/images/resource/promo2.jpg"

import { useNavigate } from "react-router-dom";

const AboutV1 = () => {
    const navigate = useNavigate();
    return (
        <>
            <section className="about-section">
                {/* <div className="anim-icons full-width">
                    <span className="icon icon-circle-blue"></span>
                    <div>
                        <span className="icon icon-dots"></span>
                    </div>
                    <div>
                        <span className="icon icon-circle-1"></span>
                    </div>
                </div> */}
                <div className="auto-container">
                    <div className="row">
                        <div className="content-column col-lg-6 col-md-12 col-sm-12">
                            <div className="inner-column">
                                <div className="sec-title">
                                    <span className="title">ABOUT WRAPPED IN GRACE CONFERENCE</span>
                                    <h2>Welcome to the Wrapped in Grace Conference 2024</h2>
                                    <div className="text">
                                    Grace sets you apart! 
                                    </div>
                                    <div className="text">
                                    But when God, who set me apart from my mother's womb and called me by
                                    his grace, was pleased (Galatians 1:15)
                                    
                                    </div>
                                    <div className="text">
                                    The Wrapped in Grace conference is
                                    designed to offer insights, teachings on the
                                    various facets of grace, and its significance in our lives. Throughout the conference, we will delve into three significant themes: the
                                    grace of salvation, the grace of healing, and the grace of entrepreneurship.
                                    </div>

                                    <div className="text">
                                    This event will also bring a transformative experience, reminding participants that grace is still available to them, no matter their past experiences or struggles.
                                    </div>
                                </div>

                                <span style = {{fontWeight:"bolder", color:'#1e1f36', fontSize: 24}} >WHAT WE ARE OFFERING</span>

                                <ul style = {{marginTop: 30}} className="list-style-one">
                                    {AboutV1Data.map(aboutData =>
                                        <li key={aboutData.id}>{aboutData.listData}</li>
                                    )}
                                </ul>
                                {/* <div className="btn-box"><div onClick={() => navigate('/pricing')} className="theme-btn btn-style-three"><span className="btn-title">Sponsor our Event</span></div></div> */}
                                <p style={{color:'pink'}}>*Registration for Wrapped In Grace Conference & Nets of Prayer</p>
                            <a href="https://www.itickets.com/events/480901">
                            <div className="form-group text-center" style={{marginBottom: 30}}>
                                <button className="theme-btn btn-style-four"><span className="btn-title">Click to Register</span></button>
                            </div>
                            </a>
                            </div>
                        </div>
                        <div className="image-column col-lg-6 col-md-12 col-sm-12">
                            <div className="image-box">
                                <div>
                                    <figure className="image"><img src={aboutImg} alt="image" /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                {/* <div  className="auto-container" >
                <h2 style={{color:"#000", fontWeight:"bolder", fontSize: window.innerWidth <= 800 ? 28:38, width: window.innerWidth <= 800 ? 350:800, marginTop: 30}}>Promotions to the Wrapped in Grace Conference 2024</h2>

                    <div className="row" style={{marginTop: 30}}>
                    <div className="image-column col-lg-4 col-md-12 col-sm-12">

                            <section className="register-section">
                <div className="auto-container">
                    <div className="outer-box">
                        <div className="row no-gutters">
                            <div className="title-column col-lg-12 col-md-12 col-sm-12">
                            <div >
                                    <div style={{padding: 30}}>
                                        <h2 style={{fontSize:28, fontWeight:'bolder', textShadow: '2px 2px #000'}}>Get Our Early bird special promotion</h2>
                                        <div style={{height:80, width:80, borderRadius: 50, backgroundColor: '#fff', marginTop: 20, alignContent:'center'}}>
                                            <p style={{ fontSize: 18, textAlign:'center', fontWeight:"900", color: "#000", marginTop:12}}>$49.99</p>
                                        </div>
                                        <h2 style={{fontSize:20, fontWeight:'bold', marginTop: 20, textShadow: '2px 2px #000'}}>Limited time apply, secure ticket now</h2>

                                        <a href="https://www.itickets.com/events/480901">
                                        <div className="form-group text-center" style={{marginTop: 40}}>
                                        <button className="theme-btn btn-style-four"><span className="btn-title">Click / Press to Register</span></button>
                                        </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                        </div>
                        <div className="image-column col-lg-4 col-md-12 col-sm-12">
                            <section className="register-section">
                <div className="auto-container">
                    <div className="outer-box">
                        <div className="row no-gutters">
                            <div className="title-column2 col-lg-12 col-md-12 col-sm-12">
                                <div >
                                    <div style={{padding: 30}}>
                                        <h2 style={{fontSize:28, fontWeight:'bolder', textShadow: '2px 2px #000'}}>Save Big with our Group Savings</h2>
                                        <div style={{height:80, width:80, borderRadius: 50, backgroundColor: '#fff', marginTop: 20, alignContent:'center'}}>
                                            <p style={{ fontSize: 18, textAlign:'center', fontWeight:"900", color: "#000", marginTop:12}}>$59.99</p>
                                        </div>
                                        <h2 style={{fontSize:20, fontWeight:'bold', marginTop: 20, textShadow: '2px 2px #000'}}>Group must consist of 4 people ( $59.99 per person )</h2>
                                        <a href="https://www.itickets.com/events/480901">
                                        <div className="form-group text-center" style={{marginTop: 40}}>
                                        <button className="theme-btn btn-style-four"><span className="btn-title">Click / Press to Register</span></button>
                                        </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
                        </div>

                        <div className="image-column col-lg-4 col-md-12 col-sm-12">

<section className="register-section">
<div className="auto-container">

<div className="outer-box">
<div className="row no-gutters">
<div className="title-column3 col-lg-12 col-md-12 col-sm-12">
<div >
        <div style={{padding: 30}}>
            <h2 style={{fontSize:28, fontWeight:'bolder',textShadow: '2px 2px #000'}}>Get Our Kiddies special promotion</h2>
            <div style={{height:80, width:80, borderRadius: 50, backgroundColor: '#fff', marginTop: 20, alignContent:'center'}}>
                <p style={{ fontSize: 18, textAlign:'center', fontWeight:"900", color: "#000", marginTop:12}}>$29.99</p>
            </div>
            <h2 style={{fontSize:20, fontWeight:'bold', marginTop: 20, textShadow: '2px 2px #000'}}>Limited for kids from 5 to 15 years</h2>
            <a href="https://www.itickets.com/events/480901">
            <div className="form-group text-center" style={{marginTop: 40}}>
            <button className="theme-btn btn-style-four"><span className="btn-title">Click / Press to Register</span></button>
            </div>
            </a>
        </div>
    </div>
</div>
</div>
</div>
</div>
</section>
</div>
                    </div>
                </div> */}


            </section>
        </>
    );
};

export default AboutV1;