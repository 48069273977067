import React from 'react';
import HeaderV1 from '../../components/header/HeaderV1';
import { useNavigate } from "react-router-dom";


const SuccessPage = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="page-wrapper">
                <span className="header-span"></span>
                <HeaderV1 headerStyle="header-style-two" parentMenu='about' />
                <div style={{display:"flex", justifyContent:"center", marginTop:25, marginBottom:25}}>
                <h2>Succesful Transaction</h2>
                </div>
                <div style={{textAlign:"center"}}>
                    <p>
                        Thank you for your Donation, our team cant wait to meet you.
                    </p>
                    <p>
                        Please make sure that you have booked for the accomodation for you and your guests. .
                    </p>
                    
                    <p>
                        If you have not booked for any accomodation, please follow <a href='https://www.marriott.com/en-us/hotels/swfrn-residence-inn-middletown-goshen/overview/'>RESIDENCE INN MIDDLETOWN GOSHEN</a>
                        
                    </p>

                    <p style={{marginTop:50, fontWeight:"bolder"}}>
                        God bless you aboundately
                    </p>

                    <div style={{display:"flex", justifyContent:"center" }} className="form-group text-end">
                    <button onClick={() => navigate("/home")} className="theme-btn btn-style-four"><span className="btn-title">Home</span></button>
                    </div>
                    
                </div>
               
                
            </div>
        </>
    );
};

export default SuccessPage;