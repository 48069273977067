import React from 'react';
import HeaderV1 from '../../components/header/HeaderV1';

const CancelPage = () => {
    return (
        <>
            <div className="page-wrapper">
                <span className="header-span"></span>
                <HeaderV1 headerStyle="header-style-two" parentMenu='about' />
                <div style={{display:"flex", justifyContent:"center", marginTop:25, marginBottom:25}}>
                <h2>Transaction Cancelled</h2>
                </div>

               
                
            </div>
        </>
    );
};

export default CancelPage;