import React from 'react';
import { HashLink as Link } from 'react-router-hash-link'
import { loadStripe } from "@stripe/stripe-js";


let stripePromise

const getStripe = () => {
    if(!stripePromise) {
         stripePromise = loadStripe("pk_live_51OvfbvGEwpwhdoHXEe2R5RhkyFZkokf1PFxygZCxVhWcBNZttKllbQTemvcFzxKHSgYdfOeWIW50kr8sUAMVWQsF00KT5AfW3b");
    }
    return stripePromise;
}


const SinglePriceV2 = ({ price }) => {
    const { icon, title, cost, plan, list1, list2, list3, list4, list5, listClass, btnText, animationName, delay } = price

    console.log("@@@@hhhh",window.location.origin);
    const checkoutOptions = {
        lineItems: [plan],
        mode: "payment",
        successUrl: 'https://perfectionmountain.com',
        cancelUrl: 'https://perfectionmountain.com',
    }

    const redirectToCheckout = async () => {
        console.log("redirectToCheckout")

        const stripe = await getStripe()
        const { error } = await stripe.redirectToCheckout(checkoutOptions);
        console.log("stripe checkout error", error);
    }

    return (
        <>
                <div className="inner-box">
                    <div className="title"><span className={icon}></span>{title}</div>
                    <div className="price-box">
                        <h4 className="price">${cost}</h4>
                    </div>

                    <div className="btn-box">
                        <div onClick={() => redirectToCheckout()} className="theme-btn btn-style-one"><span className="btn-title">{btnText}</span></div>
                    </div>
                </div>

        </>
    );
};

export default SinglePriceV2;