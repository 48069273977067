import React from 'react';
import RegisterForm from './RegisterForm';

const RegisterV1 = () => {
    return (
        <>
            <section className="register-section">
                <div className="auto-container">
                    {/* <div className="anim-icons full-width">
                        <span className="icon icon-circle-3 wow zoomIn"></span>
                    </div> */}
                    <div className="outer-box">
                        <div className="row no-gutters">
                        <div className="sec-title light">
                                        <h2>NETS OF PRAYER</h2>
                                        <div className="text light">
                                        On March 16th 2025, the Wrapped in Grace conference, in collaboration with the Brooklyn
                                        Nets, will be hosting the Nets of Prayer at the Barclays Center for a memorable day of
                                        prayer and worship.
                                        </div>
                                    </div>
                            <p style={{color:'pink', textAlign: 'center'}}>*Registration for Wrapped In Grace Conference & Nets of Prayer</p>

                            <a href="https://www.itickets.com/events/480901">
                            <div className="form-group text-center" style={{marginBottom: 30}}>
                                <button className="theme-btn btn-style-four"><span className="btn-title">Click to Register</span></button>
                            </div>
                            </a>
                            <div className="c col-lg-12 col-md-12 col-sm-12">
                                <div className="inner">
                                    {/* <div className="sec-title light">
                                        <h2 style={{textShadow: '2px 2px #000'}}>NETS OF PRAYER</h2>
                                        <div className="text light">
                                        On March 16th 2025, the Wrapped in Grace conference, in collaboration with the Brooklyn
                                        Nets, will be hosting the Nets of Prayer at the Barclays Center for a memorable day of
                                        prayer and worship.
                                        </div>
                                    </div> */}
                                </div>

                            {/* <a href="https://tithe.ly/event-registration/#/9260135">
                            <div className="form-group text-center" style={{marginBottom: 30}}>
                                <button className="theme-btn btn-style-four"><span className="btn-title">Click to Register</span></button>
                            </div>
                            </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RegisterV1;