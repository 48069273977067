import React from 'react';
import SpeakerV1Data from '../../jsonData/speaker/SpeakerV1Data.json'
import SingleSpeakerV1 from './SingleSpeakerV1';

const SpeakerV1 = () => {
    return (
        <>
            <section className="speakers-section">
                <div className="auto-container">
                    <div className="sec-title light text-center">
                        <span className="title">Guests</span>
                        <h2>Meet our  Guests</h2>
                    </div>
                    <div className="row">
                        {SpeakerV1Data.map(speaker =>
                            <div className="speaker-block  col-md-4 col-sm-12 col-md-4 mx-auto" key={speaker.id}>
                                <SingleSpeakerV1 speaker={speaker} />
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default SpeakerV1;