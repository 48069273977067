import React from 'react';
import { HashLink as Link } from 'react-router-hash-link'
import SocialShare from '../others/SocialShare';
import { Gallery } from 'react-photoswipe-gallery';
import GalleryV1Data from '../../jsonData/gallery/GalleryV1Data.json'
import SingleGalleryV1 from '../gallery/SingleGalleryV1';

const FooterV1 = () => {
    return (
        <>
            <footer className="main-footer">
                <div className="widgets-section">
                    <div className="auto-container">
                        <div className="row">
                            <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="footer-column col-xl-7 col-lg-6 col-md-6 col-sm-12">
                                        <div className="footer-widget about-widget">
                                            <div className="logo">
                                                <Link to="/#"><img src="/images/Artboard 1_2.png" alt="image" /></Link>
                                            </div>
                                            {/* <div className="text" style>
                                                <p>We have partnered with to provide to you the best accomodation experiences while. Please make sure you book your stay after registration</p>
                                            </div> */}
                                            {/* <ul className="social-icon-one social-icon-colored">
                                                <SocialShare />
                                            </ul> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="big-column col-xl-6 col-lg-12 col-md-12 col-sm-12">
                                <div className="row">
                                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                        {/* <div className="footer-widget contact-widget">
                                            <h2 className="widget-title">Contact Us</h2>
                                            <div className="widget-content">
                                                <ul className="contact-list">
                                                    <li>
                                                        <span className="icon flaticon-clock"></span>
                                                        <div className="text">Mon - Fri: 09:00 - 18:00</div>
                                                    </li>
                                                    <li>
                                                        <span className="icon flaticon-phone"></span>
                                                        <div className="text"><Link to="tel:+19739808105">+1 973 980 8105</Link></div>
                                                    </li>

                                                    <li>
                                                        <span className="icon flaticon-worldwide"></span>
                                                        <div className="text">20 hartwood Road  <br /> forestburgh, NY 12777</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="footer-column col-lg-6 col-md-6 col-sm-12">
                                        <div className="footer-widget contact-widget">
                                            <h2 className="widget-title">Contact Us</h2>
                                            <div className="widget-content">
                                                <ul className="contact-list">
                                                    <li>
                                                        <span className="icon flaticon-clock"></span>
                                                        <div className="text">Mon - Fri: 09:00 - 18:00</div>
                                                    </li>
                                                    <li>
                                                        <span className="icon flaticon-phone"></span>
                                                        <div className="text"><Link to="tel:+19739808105">+1 973 980 8105</Link></div>
                                                    </li>

                                                    <li>
                                                        <span className="icon flaticon-worldwide"></span>
                                                        <div className="text">118 -35 Farmers BLVD, Saint Albans, NY 11412. <br/> Edward Davis Center</div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="auto-container">
                        <div className="inner-container clearfix">
                            <div className="copyright-text">
                                <p>&copy; Copyright {(new Date().getFullYear())} All Rights Reserved by TNCC Media</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default FooterV1;