import React from 'react';
import { HashLink as Link } from 'react-router-hash-link'

import { useNavigate } from "react-router-dom";





const MainMenu = ({ parentMenu, toggleMenu, toggleMultiMenu }) => {
    const navigate = useNavigate();

    const goHome = () => {
        navigate("/home")
        window.scrollTo( {
            top: 0,
            behavior: 'smooth'
        } );
    }
    
    const goAbout = () => {
        navigate("/home")
        window.scrollTo( {
            top: 900,
            behavior: 'smooth'
        } );
    }
    
    const goSpeakers = () => {
        navigate("/home")
        window.scrollTo( {
            top: 2800,
            behavior: 'smooth'
        } );
        
    }
    
    const goSchedule = () => {
        navigate("/home")
        window.scrollTo( {
            top: 3900,
            behavior: 'smooth'
        } );
        
    }

    const goRegister = () => {
        navigate("/home")
        window.scrollTo( {
            top: 4800,
            behavior: 'smooth'
        } );
        
    }
    return (
        <>
            <ul style={{marginTop:15}} className="navigation clearfix">
                <li style={{fontWeight:"bolder"}}  className={`dropdown ${parentMenu === 'home' ? 'current' : ''} `}>
                    <div onClick={() => goHome()}>Home</div>
                </li>
                <li style={{fontWeight:"bolder"}} className={`dropdown ${parentMenu === 'about' ? 'current' : ''}`}>
                    <div onClick={() => goAbout()}>About</div>
                </li>
                <li style={{fontWeight:"bolder"}} className={`dropdown ${parentMenu === 'speakers' ? 'current' : ''}`}>
                    <div onClick={() => goSpeakers()}>Speakers</div>
                </li>
                <li style={{fontWeight:"bolder"}} className={`dropdown ${parentMenu === 'schedule' ? 'current' : ''}`}>
                    <div onClick={() => goSchedule()}>Schedule</div>
                </li>
                <li style={{fontWeight:"bolder"}} className={`dropdown ${parentMenu === 'register' ? 'current' : ''}`}>
                    <div onClick={() => goRegister()}>Nets of prayer Event</div>
                </li>
            </ul>
        </>
    );
};

export default MainMenu;